<template>
    <div class="home-body-list-work-container">
        <p>Проверка на измену на полиграфе занимает немного времени, сама проверка длится от двух до трёх часов,
            заключение по проверке можно получить максимум на следующий день. В отличии от других способов,
            например: обращение в детективное агентство, которое не
            гарантирует никакого результата и которое зачастую само может обратиться за помощью к полиграфологу. </p>
        <p>Возможна также проверка на детекторе лжи, перед вступлением в брак. Такая проверка выявляет наличие тайных 
            мотивов у будущей жены или мужа. Такими мотивами может быть: </p>
        <ul>
            <li>желание завладеть денежными средствами;</li>
            <li>недвижимостью</li>
            <li>решение вопросов регистрации и гражданства</li>
        </ul>
        <p>Бывают случаи, чтобы возобновить обоюдное доверие в семье, муж и жена, готовы к прохождению теста на
            детекторе лжи. В таком случае мы предоставляем скидку в 50% на вторую проверку.</p>
        <p>Мы не занимаемся гаданием или предсказыванием будущего, мы выявляем ложь и устанавливаем истину, используя самое современное оборудование и передовые методики распознавания лжи.</p>

    </div>
</template>

<script>
    export default {
        name: "HomeBodyListTreason"
    }
</script>

<style scoped>
    .home-body-list-work-container{
        max-width: 1280px;
        padding: 1rem;
        margin: 1rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
</style>