<template>
    <div class="home-list-wrapper">
        <div class="list-container">

            <div class="list-title">
                <div class="list-logo-container">
                    <img src="../../assets/icons8-hr-64.png" alt="">
                </div>
                <div class="list-title-container">
                    <h3 class="list-title-item"><router-link class="submenu-item" :to="{name:'work'}">
                     ПРОВЕРКИ КАНДИДАТОВ ПРИ ТРУДОУСТРОЙСТВЕ</router-link></h3>
                        <h3 class="list-title-item"><router-link class="submenu-item" :to="{name:'work'}">
                            ПРОВЕРКА ДЕЙСТВУЮЩИХ РАБОТНИКОВ</router-link>
                        </h3>
                </div>

            </div>
            <div class="list-desc">
                <p>"Когда я нахожу сотрудника, который, как оказывается, не годен для работы, я чувствую свою вину,
                    потому что это я принял решение нанять его" – Акио Морита, основатель корпорации Sony.</p>
                <p>После череды кадровых ошибок в своей компании, Акио Морита принял решение о
                    проверке кандидатов на работу в корпорации Sony на полиграфе. Позже было принято решение о
                    проверке уже работающих сотрудников.
                    Эта мера  заметно улучшила качество кадров в компании и сделала ее более успешной.</p>
                <p>Есть люди, которые говорят, что знают, как заработать миллионы на Форексе,
                    но вместо этого обучают этому других всего за 300$.</p>
                <p>Кандидаты на престижную работу зачастую преувеличивают свои знания и реальный опыт работы,
                    что бы ввести в заблуждение работодателя.Проверка на детекторе лжи
                    кандидата на руководящую должность в компании выявляет достоверность
                    предоставляемых работодателю сведений о своих навыках,
                    опыте работы, реальных мотивов трудоустройства на данную должность.
                </p>
            </div>
        </div>
        <div class="list-container">

            <div class="list-title">
                <div class="list-logo-container">
                    <img width="64" height="64" src="../../assets/icons8-сердце.gif" alt="">
                </div>
                <div class="list-title-container">
                    <h3 class="list-title-item"><router-link class="submenu-item" :to="{name:'treason'}">
                        ПРОВЕРКА НА ИЗМЕНУ</router-link>
                    </h3>

                </div>

            </div>
            <div class="list-desc">
                <p>Полуправда опаснее лжи; ложь легче распознать, чем полуправду,
                    которая обычно маскируется, чтобы обманывать вдвойне.(Теодор Гиппель)
                    Современные методики распознавания лжи помогают выявить любые оттенки
                    лжи и установить истину.
                </p>
                <p>Любовница знает, что её мужчина — врун, тогда как жена лишь догадывается.(Джон Апдайк)
                    Проверка с помощью детектора лжи-эффективный способ узнать о наличии
                    любовницы\любовника, либо понять, что подозрения беспочвенны.
                </p>
                <p>— Мы всего лишь друзья. Я всего лишь повел ее выпить кофе.</p>
                <p>— Ты и со мной так начинал: всего лишь подвез до дома, всего лишь угостил кофе,
                    всего лишь позвал работать у тебя, всего лишь переспал.. (Брайанна Рид)
                    Проверка с помощью детектора лжи выявляет даже факт единичной измены или
                    опровергает факт измены с высокой степенью достоверности.
                </p>
                <p>Любовь — понятие относительное. Сегодня любовь со мной, а завтра смотришь:
                    уже у Анджелины Джоли. (Дженифер Энистон)</p>
                <p>Проверка с применением детектора лжи может выявить психологические проблемы в
                    семье и предотвратить будущую измену и развал семьи.</p>
                <p>Я рада, что ты мне изменил. Спасибо тебе. Потому что если бы не это, я бы вышла за тебя замуж,
                    и тогда бы ты сделал мою жизнь ещё хуже.(Сериал «Новая девушка»)</p>
                <p>Проверка на детекторе лжи распознает ложь и измену не только в отношениях,
                    когда пара уже длительное время вместе, но и когда люди встречаются непродолжительное время.
                    Проверка поможет узнать истину о партнере и о возможных мотивах вступления в брак.
                </p>
            </div>


        </div>
        <div class="list-container">

            <div class="list-title">
                <div class="list-logo-container">
                    <img src="../../assets/icons8-private-investigator-64.png" alt="">
                </div>
                <div class="list-title-container">
                    <h3 class="list-title-item"><router-link class="submenu-item" :to="{name:'court'}">
                        СЛУЖЕБНОЕ РАССЛЕДОВАНИЕ С ПРИМЕНЕНИЕМ ДЕТЕКТОРА
                        ЛЖИ ПРИ ХИЩЕНИЯХ НА РАБОТЕ И ДОМА</router-link>
                    </h3>
                </div>

            </div>
            <div class="list-desc">
                <p>Если в Вашей организации обнаружена недостача, произошло хищение или иные противоправные действия,
                    то наиболее эффективным методом найти виновного является  тестирование на полиграфе.
                    Подобные проверки широко используются как для поиска похищенного, так и для профилактики
                    подобных явлений в будущем. Еще одним важным моментом прохождения теста на полиграфе
                    является возможность доказать непричастность лиц, так или иначе заподозренных в хищении.</p>
                <p>Еще один день на работе — ещё одна украденная ручка(сериал Симпсоны)
                    Проверка на детекторе лжи эффективно выявляет людей склонных к хищениям, а также лиц,
                    которые уже совершали хищения на прошлых местах работы.
                </p>
                <p>А меня же, Зинаида Михайловна, обокрали! Собака с милицией обещала прийти.
                    (фильм «Иван Васильевич меняет профессию»)</p>
                <p>В цивилизованном мире многие организации предпочитают обратиться к профессиональному полиграфологу
                    для выявления виновного в хищениях лица.
                    Это зачастую гораздо эффективнее и создаёт меньше хлопот, чем обращение в правоохранительные органы.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HomeList"
    }
</script>

<style scoped>
    a{
        list-style: none;
    }
.home-list-wrapper{
    max-width: 1480px;

}
    .list-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 3rem;
        padding-top: 2rem;
        margin-top: 2rem;
    }
    @media screen and (max-width: 1058px) {
        .list-container{
            display: grid;
            grid-template-columns: 1fr;

            padding-top: 2rem;
            padding-left: 1rem;
            padding-right: 1rem;
            margin-right: 1rem;
            margin-left: 1rem;
            margin-top: 2rem;
        }
        .list-desc{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 1rem;
            margin: 1rem;
            background-color: #c4c4c4;
        }
        .list-logo-container{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            margin: 1rem;
        }
           }
    .list-title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .list-title-container{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-top: 1rem;
    }
    .list-logo-container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .list-title-item{
        color: #3F51B5;
        list-style: none;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
    }

    .list-desc{
        padding: .5rem;
        background-color: #c4c4c4;
    }
    .list-title-item>*{
        text-align: center;
        text-decoration: none;
        color: #3F51B5;
    }

</style>