<template>
    <div class="home-body-list-work-container">
        <p>Проверка на детекторе лжи вызовет у подростка меньше негатива, чем визит к психологу или психиатру,
            так как визит к полиграфологу зачастую воспринимается подростком как игра,
            эта процедура им будет интересна сама по себе.
            Подросток уверен, что в случае необходимости детектор лжи можно обмануть.  </p>
        <p>Обращение к опытному полиграфологу поможет выявить истинные причины особенностей поведения
            Вашего ребенка и предотвратить беду, благодаря информации,
            полученной в результате проведённой проверке, вернуть в семью спокойствие и доверие. </p>
        <p>Дети до 14 лет не могут проходить проверки на полиграфе. Подростки с 14 до 18 лет проходят проверку с
            письменного разрешения одного из родителей или законного представителя.</p>
        <p>Проверка на детекторе лжи (полиграфе) абсолютно безопасна и эффективна, благодаря использованию самого
            современного оборудования и передовых методик в области распознавания лжи.</p>

    </div>
</template>

<script>
    export default {
        name: "HomeBodyListTeens"
    }
</script>

<style scoped>
    .home-body-list-work-container{
        max-width: 1280px;
        padding: 1rem;
        margin: 1rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
</style>