<template>
    <div class="history-container" >
        <h1 class="history-title">История полиграфа</h1>
        <div class="history-container-desc">
            <h2 class="p-history"> Ложь и правда — взаимоисключающие социальные понятия.
                Развитие цивилизации накладывало отпечаток на системы получения
                информации о правде и лжи человека. </h2>
            <p class="p-history">          Знаменитый врач древности Авиценна определял по пульсу скрываемую информацию.
                В качестве информативных признаков брались изменения частоты пульса и особенности
                кровенаполнения артерий. Применявшаяся нашими далекими предками методика тестирования,
                была далека от совершенства, но
                в впоследствии стала поисковым методом и в настоящее время широко используется
                в практике детекции лжи.</p>
            <p class="p-history">В период Новой истории использование приемов для детекции лжи описал Даниель
                Дефо (1730 г.). Он говорил: «Вина всегда сопровождается страхом; будучи измеренная
                толчком в крови вора, непременно его выдаст, если имеется подозреваемый,
                я бы измерил его пульс».</p>
            <p class="p-history"><img class="img-fluid" src="../../assets/stock-photo-woman-light-hair-dressed-black.jpg" alt="">
                В 1895 г итальянский врач-психиатр Чезаре Ломброзо использовал первый прибор для детекции лжи
                —гидросфигмометр, который регистрировал у человека изменение давления крови. Через семь лет, в 1902 г,
                с помощью инструментальных
                методик удалось впервые доказать в суде непричастность обвиняемого в совершении преступления.
            </p>
            <p class="p-history">
                Первый полиграф, пригодный для расследования преступлений, был создан Джоном Ларсеном. В 1921г.
                данный прибор регистрировал на движущейся бумажной ленте пульс, давление крови и дыхание.
                Несмотря на свою прогрессивность, он был еще далек от современных полиграфов, но для того времени это
                было высокотехнологичное устройство. В том же году
                американский психолог и юрист Уильям Марстон впервые использовал результаты полиграфных проверок в суде.
            </p>
            <p class="p-history">Введение канала регистрации кожного сопротивления Леонардом Килером в 1926 г.
                значительно повысило точность прогноза при проведении
                полиграфных обследований. В некоторых странах на государственном уровне
                стали обращать внимание на новые методики выявления лжи.
            </p>
            <p class="p-history">         В Советской России к полиграфу относились долгое время отрицательно. Одним из самых рьяных
                противников полиграфа был печально известный участием в сталинских репрессиях А.Я.Вышинский.</p>
            <p class="p-history">         В 1945г. полиграф впервые был удачно использован в американской
                контрразведке для решения кадровых проблем, во время назначения новых властей в Европе.
                Это делалось с целью выявления среди кандидатов на должности нацистов.
            </p>
            <p class="p-history">В современном мире первенство по использованию проверок с использованием полиграфа во всех сферах у
                таких стран как США, Япония и Канада.</p>
            <p class="p-history">Во многих развитых странах подобные проверки принимаются как доказательство в суде, а также
                используется при проверках при приёме на работу, служебных и уголовных расследованиях,
                выявления виновных в недостачах и хищениях,
                установлении супружеских измен, выявлении агентов. Фактически проверки с использованием
                детектора лжи сейчас применяют   для выявления любой скрываемой информации и при
                профессиональном полиграфологе, современном оборудовании и передовых методиках,
                обмануть полиграф практически невозможно.</p>
        </div>
    <div class="icon-line"><IconsContainer/></div>
    </div>
</template>

<script>
    import IconsContainer from "@/components/IconsContainer";
    export default {
        name: "HistoryMain",
        components:{
            IconsContainer
        }
    }
</script>

<style scoped>
.history-container{
    max-width: 1480px;
    display: flex;
   flex-direction: column;
    justify-content: center;
    align-items: center;

}
    .history-title{
        text-align: center;
        width: 100%;
        color: #3F51B5;
    }
    .icon-line{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .history-container-desc{
        width: 100%;
        padding: 1rem;
        margin: 1rem;
        box-sizing: content-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .img-fluid{
        width: 100%;
        height: auto;
        padding: .5rem;
        margin: .5rem;
    }
    .p-history{
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-left: 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }
</style>