<template>
    <div class="home-body-list-work-container">
        <p>Бывают случаи, когда кто-то устраиваются на работу зная,
            что там можно украсть товарно-материальные ценности (например: в магазин),
            брать откаты или взятки (например: менеджером или в отдел снабжения),
            получить доступ к информации для ее продажи или другого использования
            (например: в канцелярию, секретарём),
            совершить хищения из дома или завладеть личной информацией с целью шантажа. </p>
        <p>Неприятных ситуаций можно избежать, если проверить кандидата на вакансию на детекторе лжи, выяснив
            его реальное прошлое, мотивы устроится на работу, возможные пагубные пристрастия. </p>
        <p>Во всём цивилизованном мире, проверка при трудоустройстве — это нормальная процедура,
            которую проводят, как государственные, так и коммерческие структуры.
            Заказчиком данной услуги могут быть и частные лица, например для проверки домашнего персонала.</p>
        <p>Проверка на детекторе лжи (полиграфе) абсолютно безопасна и эффективна, благодаря использованию самого
            современного оборудования и передовых методик в области распознавания лжи.</p>
        <h3 style="color: crimson">При заказе на проверку трёх и более человек, предоставляются скидки.</h3>
    </div>
</template>

<script>
    export default {
        name: "HomeBodyListWork"
    }
</script>

<style scoped>
.home-body-list-work-container{
    max-width: 1280px;
    padding: 1rem;
    margin: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
</style>