<template>
    <div class="home-title-image-container">
        <img class="home-title-image-settings" src="../../assets/IMG_5131_1920x2880.jpeg">
    </div>
</template>

<script>
    export default {
        name: "HomeTitleImageTreason"
    }
</script>

<style scoped>
    .home-title-image-container{
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    .home-title-image-settings{
        margin: 2rem;
        width: 60%;
        height: auto;
    }
</style>