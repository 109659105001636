import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router';

//Routes





const app = createApp(App)
    app.use(router)
    .mount('#app')
