<template>
    <div class="copyright">
        &#169; Poligraf2023
  </div>
</template>

<script>
    export default {
        name: "CopyRight"
    }
</script>

<style scoped>
.copyright{
    background-color:  #3F51B5;


    width: 100%;

    display: flex;
    justify-content: center;
}
</style>